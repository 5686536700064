<template>
  <div id="amNetworkBubble" ref="networkBubbleChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/networkBubbles.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      let chart = am4core.create(
        this.$refs.networkBubbleChart,
        am4plugins_forceDirected.ForceDirectedTree
      );
      let networkSeries = chart.series.push(
        new am4plugins_forceDirected.ForceDirectedSeries()
      );
      chart.data = this.info;
      // chart.data = chartData;

      networkSeries.dataFields.linkWith = "linkWith";
      networkSeries.dataFields.name = "name";
      networkSeries.dataFields.id = "name";
      networkSeries.dataFields.value = "value";
      networkSeries.dataFields.children = "children";

      networkSeries.nodes.template.tooltipText = "{name} : {value}";
      networkSeries.nodes.template.fillOpacity = 1;

      networkSeries.nodes.template.label.text = "{name}";
      networkSeries.fontSize = 12;
      networkSeries.maxLevels = 2;
      networkSeries.maxRadius = am4core.percent(15);
      networkSeries.manyBodyStrength = -20;
      networkSeries.nodes.template.label.hideOversized = true;
      networkSeries.nodes.template.label.truncate = true;
      networkSeries.nodes.template.label.fill = "#FAFAFA";
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
