<template>
  <div id="amWordCloud" ref="wordCloudChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/wordCloudData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      let chart = am4core.create(
        this.$refs.wordCloudChart,
        am4plugins_wordCloud.WordCloud
      );

      let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
      series.data = this.info;
      // series.data = chartData;

      series.dataFields.word = "country";
      series.dataFields.value = "litres";

      // series.dataFields.word = "tag";
      // series.dataFields.value = "weight";

      series.accuracy = 4;
      series.step = 15;
      series.rotationThreshold = 0.7;
      // series.maxCount = 200;
      // series.minWordLength = 2;
      series.labels.template.margin(4, 4, 4, 4);
      series.maxFontSize = am4core.percent(20);

      series.colors = new am4core.ColorSet();
      series.colors.passOptions = {};
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
