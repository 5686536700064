<template>
  <div id="amSunburst" ref="sunburstChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_sunburst from "@amcharts/amcharts4/plugins/sunburst";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/sunburstData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      let chart = am4core.create(this.$refs.sunburstChart, am4plugins_sunburst.Sunburst);
      // chart.padding(0, 0, 0, 0);
      chart.radius = am4core.percent(98);

      // chart.data = chartData;
      chart.data = this.info;

      chart.colors.step = 2;
      chart.fontSize = 11;
      chart.innerRadius = am4core.percent(10);

      // define data fields
      chart.dataFields.value = "value";
      chart.dataFields.name = "name";
      chart.dataFields.children = "children";

      let level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey("0", level0SeriesTemplate);

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;

      level0SeriesTemplate.labels.template.adapter.add(
        "rotation",
        function (rotation, target) {
          target.maxWidth =
            target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
          target.maxHeight = Math.abs(
            ((target.dataItem.slice.arc *
              (target.dataItem.slice.innerRadius + target.dataItem.slice.radius)) /
              2) *
              am4core.math.RADIANS
          );

          return rotation;
        }
      );

      let level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey("1", level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level1SeriesTemplate.hiddenInLegend = true;

      let level2SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey("2", level2SeriesTemplate);
      level2SeriesTemplate.fillOpacity = 0.5;
      level2SeriesTemplate.hiddenInLegend = true;

      level0SeriesTemplate.labels.template.inside = true;
      level0SeriesTemplate.labels.template.fill = "#FAFAFA";
      level1SeriesTemplate.labels.template.fill = "#FAFAFA";
      level2SeriesTemplate.labels.template.fill = "#FAFAFA";

      // chart.legend = new am4charts.Legend();
      // chart.legend.fill = this.darkTheme ? "#FAFAFA" : "#000";
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
