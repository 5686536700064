<template>
  <v-alert
    border="left"
    dense
    elevation="2"
    icon="mdi-twitter"
    colored-border
    :color="tweetColor"
    class="myTwitterAlert d-flex align-center"
  >
    <span class="user_name mr-1">{{ tweet.user.name }}</span>
    <span class="user_screen_name mr-1">·</span>
    <span class="user_screen_name mr-1">@{{ tweet.user.screen_name }}</span>
    <span class="user_screen_name mr-1">·</span>
    <span class="user_screen_name">
      <v-icon small class="mr-1"> mdi-share-variant </v-icon>{{ tweet.retweet_count }}
    </span>
    <span class="user_screen_name mr-1">·</span>
    <span class="user_screen_name">
      <v-icon small class="mr-1"> mdi-heart </v-icon>{{ tweet.favorite_count }}
    </span>
    <span class="user_screen_name mr-1">·</span>
    <span class="user_screen_name">
      <v-icon small class="mr-1"> {{ tweetIcon }} </v-icon>
    </span>
    <!-- <br /> -->
    <span class="user_screen_name mr-1">·</span>
    <span class="user_screen_name mr-1 pt-0">{{ createDate(tweet.created_at) }}</span>
    <br />
    <span class="twitter-text-truncate">{{ tweet.full_text }}</span>
  </v-alert>
</template>

<script>
export default {
  props: {
    tweet: {
      type: Object,
      required: true,
    },
    keyWords: {
      type: Array,
      required: false,
    },
  },
  methods: {
    getColor() {
      switch (this.tweet.sentimiento.toUpperCase()) {
        case "POSITIVO":
          return "success";
        case "NEUTRO":
          return "primary";
        case "NEGATIVO":
          return "error";
        case "RIESGO":
          return "warning";
        default:
          return "#00A2F3";
      }
    },
    getIcon() {
      switch (this.tweet.sentimiento.toUpperCase()) {
        case "POSITIVO":
          return "mdi-emoticon-outline";
        case "NEUTRO":
          return "mdi-emoticon-neutral-outline";
        case "NEGATIVO":
          return "mdi-emoticon-angry-outline";
        case "RIESGO":
          return "mdi-alert";
        default:
          return "mdi-emoticon-neutral-outline";
      }
    },
    createDate(stringDate) {
      let tweetDate = new Date(stringDate).toLocaleDateString("es-MX");
      return tweetDate;
    },
  },
  computed: {
    tweetColor() {
      return this.getColor();
    },
    tweetIcon() {
      return this.getIcon();
    },
  },
};
</script>
