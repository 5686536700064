<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-btn success @click="verNotas = true">
          <v-icon>mdi-eye</v-icon> Ver notas
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col cols="12" md="8" v-if="resultados">
        <v-card elevation="2">
          <Sunburst :info="resultados.sunburst" />
        </v-card>
      </v-col> -->
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.sunburst.length > 0"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center">Medios digitales</v-card-title>
          <Sunburst2 :info="resultados.sunburst" />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.tweets.data.statuses.length"
        v-scrollfadein
      >
        <TwitterAlert
          v-for="(n, index) in 7"
          :key="index"
          :tweet="resultados.tweets.data.statuses[index]"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.piechart_discovery.length"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center"
            >Sentimientos Medios digitales</v-card-title
          >
          <Pie :info="resultados.piechart_discovery" />
          <!-- <Pie /> -->
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.piechart_twitter.length"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center">Sentimientos Twitter</v-card-title>
          <Pie :info="resultados.piechart_twitter" />
          <!-- <Pie /> -->
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.palabras.length"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center">Nube de palabras</v-card-title>
          <WordCloud :info="resultados.palabras" />
          <!-- <WordCloud /> -->
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="resultados && resultados.burbujas.length"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center">Sentimientos por notas</v-card-title>
          <NetworkBubble :info="resultados.burbujas" />
        </v-card>
      </v-col>
      <v-col
        cols="12"
        v-if="resultados && resultados.histograma.length"
        v-scrollfadein
      >
        <v-card elevation="2">
          <v-card-title class="justify-center">Notas en el tiempo</v-card-title>
          <Histogram
            :info="resultados.histograma"
            @histogramClicked="showDetailDialog($event)"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="verNotas"
        fullscreen
        persistent
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Notas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="closeModal">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-row class="d-flex align-end">
              <v-col cols="12" md="4" xl="3">
                <v-breadcrumbs :items="breadCrumbs" large>
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
              <v-col cols="12" md="4" xl="3">
                <v-menu
                  ref="menu"
                  v-model="dateSelected"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="mb-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Rango de fechas"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      v-model="dateRangeText"
                      clearable
                      @click:clear="clearArray"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" no-title scrollable range>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateSelected = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(dates)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs v-model="currentTab" color="primary" grow>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab>
                    <v-icon class="mr-2">mdi-newspaper</v-icon>Medios digitales
                    {{
                      resultados && resultados.notas.notas.length > 0
                        ? "(" + filteredDocsBySentiment.length + ")"
                        : null
                    }}
                  </v-tab>
                  <v-tab
                    ><v-icon class="mr-2">mdi-twitter</v-icon>Twitter
                    {{
                      resultados && resultados.tweets.data.statuses.length > 0
                        ? "(" + filteredTweetsBySentiment.length + ")"
                        : null
                    }}</v-tab
                  >
                </v-tabs>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs-items v-model="currentTab">
                  <v-tab-item>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn-toggle v-model="sentimientosMedios" dense>
                          <v-btn> Todos </v-btn>
                          <v-btn> Positivo </v-btn>
                          <v-btn> Negativo </v-btn>
                          <v-btn> Neutro </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row class="mt-6 pa-2">
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(item, index) in filteredDocsBySentiment"
                        :key="index"
                        v-scrollfadein
                        @click="showMediosDialog(item)"
                      >
                        <DiscoveryCard :info="item" :small="true" />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn-toggle v-model="sentimientosTwitter" dense>
                          <v-btn> Todos </v-btn>
                          <v-btn> Positivo </v-btn>
                          <v-btn> Negativo </v-btn>
                          <v-btn> Neutro </v-btn>
                          <v-btn> Riesgo </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                    <v-row class="mt-6 pa-2">
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(item, index) in filteredTweetsBySentiment"
                        :key="index"
                        v-scrollfadein
                        @click="showTwitterDialog(item)"
                      >
                        <TwitterCard :tweet="item" :small="true" />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog max-width="700" v-model="dialogMedios">
      <DiscoveryCard :info="dialogCardInfo" :dialog="true" @cardClose="closeCards" />
    </v-dialog>
    <v-dialog max-width="700" v-model="dialogTwitter">
      <TwitterCard :tweet="dialogCardInfo" :dialog="true" @cardClose="closeCards" />
    </v-dialog>
  </v-container>
</template>

<script>
// import Sunburst from "@/components/charts/Sunburst";
import Sunburst2 from "@/components/charts/Sunburst2";
import Pie from "@/components/charts/Pie";
import WordCloud from "@/components/charts/WordCloud";
import Histogram from "@/components/charts/Histogram";
import NetworkBubble from "@/components/charts/NetworkBubble";
import TwitterAlert from "@/components/TwitterAlert";
import TwitterCard from "@/components/TwitterCard";
import DiscoveryCard from "@/components/DiscoveryCard";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: {
    // Sunburst,
    Sunburst2,
    Pie,
    TwitterAlert,
    TwitterCard,
    WordCloud,
    Histogram,
    NetworkBubble,
    DiscoveryCard,
  },
  data() {
    return {
      resultados: null,
      verNotas: false,
      verDeclaraciones: false,
      busqueda: "",
      dateSelected: false,
      dates: [],
      currentTab: null,
      sentimientosParaMedios: ["positive", "negative", "neutral"],
      sentimientosParaTwitter: ["Positivo", "Negativo", "Neutro", "Riesgo"],
      sentimientosMedios: 0,
      sentimientosTwitter: 0,
      dialogMedios: false,
      dialogTwitter: false,
      dialogCardInfo: null,
      breadCrumbs: [
        { text: "Panorama", disabled: true, href: "#" },
        { text: "Detalle de menciones", disabled: true, href: "#" },
      ],
      candidatos: [
        {
          nombre: "Francisco Cienfuegos",
          foto: require("@/assets/images/01.jpg"),
          size: 96,
          active: true,
        },
        {
          nombre: "Adalberto Madero Quiroga",
          foto: require("@/assets/images/02.jpg"),
          size: 70,
          active: false,
        },
        {
          nombre: "Luis Donaldo Colosio Riojas",
          foto: require("@/assets/images/03.jpg"),
          size: 70,
          active: false,
        },
        {
          nombre: "Clara Luz Flores",
          foto: require("@/assets/images/04.jpg"),
          size: 70,
          active: false,
        },
        {
          nombre: "Adrián de la Garza",
          foto: require("@/assets/images/05.jpg"),
          size: 70,
          active: false,
        },
        {
          nombre: "Samuel García",
          foto: require("@/assets/images/06.jpg"),
          size: 70,
          active: false,
        },
      ],
    };
  },
  methods: {
    setActive(index) {
      for (let i = 0; i < this.candidatos.length; i++) {
        this.candidatos[i].active = false;
        if (i == index) {
          this.candidatos[i].active = true;
          if (index === 0) {
            this.$store.commit("setCandidato", "");
          } else {
            this.$store.commit("setCandidato", this.candidatos[i].nombre);
          }
        }
      }
      this.$store.commit("startSearch");
    },
    getSearch() {
      this.resultados = null;

      let data = new FormData();
      // this.searchWord.length > 0 ? data.append(this.searchWord) : null;
      this.candidato.length > 0 ? data.append("candidato", this.candidato) : null;
      this.tema.length > 0 ? data.append("tema", this.tema) : null;
      axios
        .post("public/discovery", data, { config: { showOverlay: true } })
        .then((response) => {
          this.resultados = response.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    test(index) {
      console.log("test", index);
    },
    clearArray() {
      this.dateRange = [];
    },
    sortArray(array) {
      return array.sort();
    },
    getDocsByDate() {
      if (this.resultados) {
        if (this.dateRange.length == 2) {
          let notasTmp = [];
          let notas = this.resultados.notas.notas;
          let initDate = new Date(this.dateRange[0] + "T00:00:00-06:00").valueOf();
          let endDate = new Date(this.dateRange[1] + "T23:59:59-06:00").valueOf();
          // console.log(initDate, endDate, notas.length);
          for (let i = 0; i < notas.length; i++) {
            let notaDate = new Date(notas[i].origen_fecha + "T12:00:00-06:00").valueOf();
            // console.log(notaDate);
            if (notaDate >= initDate && notaDate <= endDate) {
              notasTmp.push(notas[i]);
            }
          }
          return notasTmp;
        } else {
          return this.resultados.notas.notas;
        }
      }
    },
    getTweetsByDate() {
      if (this.resultados) {
        if (this.dateRange.length == 2) {
          let tweetsTmp = [];
          let tweets = this.resultados.tweets.data.statuses;
          let initDate = new Date(this.dateRange[0] + "T00:00:00-06:00").valueOf();
          let endDate = new Date(this.dateRange[1] + "T23:59:59-06:00").valueOf();
          // console.log(initDate, endDate);
          for (let i = 0; i < tweets.length; i++) {
            let tweetDate = new Date(tweets[i].created_at).valueOf();
            // console.log(tweetDate);
            if (tweetDate >= initDate && tweetDate <= endDate) {
              tweetsTmp.push(tweets[i]);
            }
          }
          return tweetsTmp;
        } else {
          return this.resultados.tweets.data.statuses;
        }
      }
    },
    getDocsBySentiment() {
      if (this.sentimientosMedios > 0) {
        let tmpFilteredDocs = [];
        let sentimiento = this.sentimientosParaMedios[this.sentimientosMedios - 1];
        for (let i = 0; i < this.filteredDocsByDate.length; i++) {
          if (
            this.filteredDocsByDate[i].enrich.sentiment.document.label.toUpperCase() === sentimiento.toUpperCase()
          ) {
            tmpFilteredDocs.push(this.filteredDocsByDate[i]);
          }
        }
        return tmpFilteredDocs;
      } else {
        return this.filteredDocsByDate;
      }
    },
    getTweetsBySentiment() {
      if (this.sentimientosTwitter > 0) {
        let tmpFilteredTweets = [];
        let sentimiento = this.sentimientosParaTwitter[this.sentimientosTwitter - 1];
        for (let i = 0; i < this.filteredTweetsByDate.length; i++) {
          if (this.filteredTweetsByDate[i].sentimiento.toUpperCase() === sentimiento.toUpperCase()) {
            tmpFilteredTweets.push(this.filteredTweetsByDate[i]);
          }
        }
        return tmpFilteredTweets;
      } else {
        return this.filteredTweetsByDate;
      }
    },
    showDetailDialog(evt) {
      let date = evt.toISOString().split("T")[0];
      this.dateRange = [date, date];
      this.verNotas = true;
    },
    showTwitterDialog(card) {
      this.dialogTwitter = true;
      this.dialogCardInfo = card;
    },
    showMediosDialog(card) {
      this.dialogMedios = true;
      this.dialogCardInfo = card;
    },
    hideTwitterCard(evt) {
      this.dialogTwitter = false;
      console.log(evt);
    },
    hideMediosCard(evt) {
      this.dialogMedios = false;
      console.log(evt);
    },
    closeCards() {
      this.dialogMedios = false;
      this.dialogTwitter = false;
    },
    closeModal() {
      this.verNotas = false;
      this.sentimientosTwitter = 0;
      this.sentimientosMedios = 0;
      this.dialogMedios = false;
      this.dialogTwitter = false;
      this.dateRange = [];
    },
  },
  computed: {
    ...mapState(["searchWord", "candidato", "tema", "searching"]),
    candidatosArray() {
      return this.candidatos;
    },
    dateRange: {
      get: function () {
        return this.sortArray(this.dates);
      },
      set: function (array) {
        this.dates = array;
      },
    },
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (text) {
        return text;
      },
    },
    filteredTweetsByDate() {
      return this.getTweetsByDate();
    },
    filteredDocsByDate() {
      return this.getDocsByDate();
    },
    filteredTweetsBySentiment() {
      return this.getTweetsBySentiment();
    },
    filteredDocsBySentiment() {
      return this.getDocsBySentiment();
    },
  },
  mounted() {
    this.getSearch();
  },
  watch: {
    searching() {
      this.getSearch();
      // console.log(state);
    },
  },
};
</script>
